





























































































































































import Vue from "vue";
export default Vue.extend({
  name: "ProjectCard",
  props: ["project"],
  data: () => ({
    showDemoCredentials: false,
  }),
});
